import {DependencyList, useEffect} from "react";
import * as NativeUtil from "utils/native_util";

type HeadlessCallback = (headless: boolean) => void;

export const useHeadless = (callback: HeadlessCallback) => {
    useEffect(() => {
        window.reactHeadlessCallback = callback;
        NativeUtil.reactHeadlessReady().then((res: boolean) => {
            if (window.reactHeadlessCallback) {
                window.reactHeadlessCallback(res);
            }
        });
        return () => {
            window.reactHeadlessCallback = undefined;
        };
    }, []);
};

type NativeCallback = () => boolean;

export const useNativeBack = (callback: NativeCallback, deps: DependencyList) => {
    useEffect(() => {
        window.reactNativeBackCallback = callback;
        return () => {
            window.reactNativeBackCallback = undefined;
        };
    }, deps);
};
