import React, {useContext, useState} from 'react';
import Spinner from "../views/common/spinner";
import {useNativeBack} from "v2/views/component/gs_headless";
import NiceModal from "@ebay/nice-modal-react";

const AppContext = React.createContext();

const AppProvider = ({children}) => {
  const [loading, setLoading] = React.useState(false);
  const [isCTAButton, setIsCTAButton] = useState(true);
  const modals = useContext(NiceModal.NiceModalContext);

  const store = {
    loading,
    isCTAButton,
    setLoading,
    setIsCTAButton,
  };

  useNativeBack(() => {
    const visibleModalIds = Object.keys(modals).filter((id) => modals[id]?.visible ?? false);
    if (visibleModalIds.length === 0) {
      return false;
    }
    NiceModal.hide(visibleModalIds[visibleModalIds.length - 1]);
    return true;
  }, [modals]);

  return (
    <AppContext.Provider value={store}>
      {children}
      {loading && <Spinner/>}
    </AppContext.Provider>
  );
};

export {
  AppProvider,
  AppContext,
};
